import React, { useEffect, useState } from "react"
var width="auto";
var height="auto";
const formImageSrcSet = params => {
  const keys = Object.keys(params.transforms),
    keysLength = keys.length
  let imageSrcSet = {
    src: params.images[keys[keysLength - 1]],
    srcSet: "",
    sizes: ""
  }
  for (let index = 0; index < keysLength; index++) {
    width = keys[index].substr(0, keys[index].search(/[a-z]/i))
    height = keys[index].split('x')[1];
    const IndexKey = keys[index]
    imageSrcSet.srcSet += `${params.images[IndexKey]} ${width}w, `
    //imageSrcSet.srcSet += `${params.images[keys[index]]} ${width}w, `
    //console.log("imageSrcSet.srcSet", params.images[IndexKey], params.images, IndexKey)
    imageSrcSet.sizes += `${params.transforms[keys[index]]} ${width}px, `
  }
  if (params.isTeamParam) {
    console.log(45678, params.transforms, params.images, imageSrcSet)
  }
  return imageSrcSet;
}

const SrcSetImage = ({ props }) => {
  const [Imgs, SetImgs] = useState({});
  useEffect(() => {
    if (props.images) {
      const ImageSrcSet = formImageSrcSet(props)
      SetImgs(ImageSrcSet);
    } else {
      SetImgs({});
    }
  }, [props]);
  return <img alt={"image place"}
    // width= {width}
    // height={height}
    loading={props?.loadLazy === false ? "" : "lazy" } 
    {...Imgs}
    {...props.attr}
  />
}

 
export default SrcSetImage 
